<template>
  <div class="job-profile">
    <div class="row" v-if="properties">
      <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12">
        <div class="rounded shadow bg-white">
          <div class="text-center py-4 border-bottom">
            <h5 class="mt-3 mb-3 text-300">
              {{ properties.Brand.brand.name }} {{ properties.Brand.model }}
            </h5>
            <p class="text-lighter mb-0">
              {{ $t(`brokerage.specs.specsProperties.buildingYear`) }}
              {{ properties.buildingYear }}
            </p>
          </div>
          <div class="p-4 pb-0 col-md-12 col-sm-12">
            <ul class="list-unstyled" style="box-sizing: border-box">
              <li class="row no-icon">
                <div class="title-span col-5">
                  {{ $t("brokerage.yacht-card.dimensions") }}
                </div>
                <div class="info-span col-7" v-if="properties.Generic">
                  {{ properties.Generic.lengthOverAll / 100 }} x
                  {{ properties.Generic.beam / 100 }} x
                  <sub-component-draft
                    :yachtgenericInfo="properties.Generic"
                  ></sub-component-draft>
                </div>
              </li>
              <li class="row no-icon">
                <div
                  class="title-span col-5"
                  v-if="properties.hullMaterial?.hullMaterial?.material"
                >
                  {{ $t("brokerage.yacht-card.hull-material") }}
                </div>
                <div
                  class="info-span col-7"
                  v-if="properties.hullMaterial?.hullMaterial?.material"
                >
                  {{ properties.hullMaterial.hullMaterial.material }}
                </div>
              </li>
              <li class="row no-icon">
                <div class="title-span col-5">
                  {{ $t("brokerage.yacht-card.cabins") }}
                </div>
                <div class="info-span col-7" v-if="properties.accomodation">
                  <span v-if="properties.accomodation.areasSummary">
                    {{
                      properties.accomodation.areasSummary.cabinsInTotal
                    }}</span
                  >
                </div>
              </li>
              <li class="row no-icon">
                <div class="title-span col-5">
                  {{ $t("brokerage.yacht-card.berths") }}
                </div>
                <div class="info-span col-7" v-if="properties.accomodation">
                  <span v-if="properties.accomodation.areasSummary">
                    {{
                      properties.accomodation.areasSummary.berthsInTotal
                    }}</span
                  >
                </div>
              </li>
              <li class="row no-icon">
                <div class="title-span col-5">
                  {{ $t("brokerage.yacht-card.price") }}
                </div>
                <div class="info-span col-7">
                  <sub-component-price
                    v-if="properties.publishing && properties.price"
                    :yachtpublishingInfo="properties.publishing"
                    :yachtpriceInfo="properties.price"
                  ></sub-component-price>
                </div>
              </li>
              <li
                class="row no-icon pb-4"
                v-if="properties.publishing && properties.publishing.vatStatus"
              >
                <div class="title-span col-5">
                  {{ $t("brokerage.specs.specsProperties.vatStatus") }}
                </div>
                <div class="info-span col-7">
                  {{ properties.publishing.vatStatus.type }}
                </div>
              </li>

              <!--                  <li class="no-icon pt-5 pb-1">-->
              <!--                    <a class="submitBnt btn btn-primary btn-block">{{ $t('brokerage.buttons.schedule_viewing') }}-->
              <!--                      <i class="mdi mdi-chevron-right"></i-->
              <!--                      ></a>-->
              <!--                  </li>-->
              <!--                  <li class="no-icon pt-1 pb-3">-->
              <!--                    <a class="submitBnt btn btn-secondary btn-block">{{ $t('brokerage.buttons.request_brochure') }}-->
              <!--                      <i class="mdi mdi-chevron-right"></i-->
              <!--                      ></a>-->
              <!--                  </li>-->
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-12 col-sm-12">
        <SnippetImageSlider
          class="thumbslider"
          ref="thumbnailslider"
          nolabels
          :options="primaryOptions"
        ></SnippetImageSlider>
        <VesselDescription
          :yachtDescription="properties.salesPitch"
          :yachtbrandInfo="properties.Brand"
          :yachtboatName="properties.boatName"
        ></VesselDescription>
      </div>
    </div>
  </div>
</template>
<script>
import SnippetImageSlider from "@/components/SnippetImageSlider";
import VesselDescription from "@/components/YachtVesselDescription";
import SubComponentDraft from "@/components/subComponentDraft";
import SubComponentPrice from "@/components/subComponentPrice";
export default {
  data() {
    return {
      loading: true,
      primaryOptions: {
        rewind: true,
        gap: "1rem",
        perPage: 6,
        pagination: false,
        fixedWidth: 120,
        fixedHeight: 80,
        height: "15rem",
        autoplay: true,
        cover: false,
        arrows: false,
        focus: "left",
        isNavigation: true,
        updateOnMove: true,
        lazyLoad: true,
      },
    };
  },
  components: {
    SubComponentPrice,
    SubComponentDraft,
    SnippetImageSlider,
    VesselDescription,
  },
  props: {
    properties: {
      type: Object,
    },
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    showModal(modal) {
      if (process.client) {
        if (modal === "Viewing") {
          // this.isModalBrochureVisible = true
          this.$bus.$emit("isModalViewingVisible", true);
        }
      }
    },
  },
};
</script>
<style scoped>
/*@media (min-width: 481px) and (max-width: 767px) {*/
/*  .info-span {*/
/*      font-size: clamp(16px, 4vw, 22px);*/
/*  }*/
/*  .title-span {*/
/*      font-size: clamp(16px, 4vw, 22px);*/
/*  }*/
/*}*/
/*@media (min-width: 320px) and (max-width: 480px) {*/
/*  .info-span {*/
/*    font-size: 14px;*/
/*  }*/
/*  .title-span {*/
/*    font-size: 14px;*/
/*  }*/
/*}*/

.content {
  background-color: #fff;
  margin: 50px;
  padding: 50px;
}
/*.videoWrapper {*/
/*  position: relative;*/
/*  padding-bottom: 56.25%; !* 16:9 *!*/
/*  width: 90%;*/
/*  margin: auto;*/
/*}*/
/*.videoWrapper iframe {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 90%;*/
/*  background-color: #06173e;*/
/*  opacity: 1;*/
/*  z-index: 10;*/
/*  border-radius: 14px;*/
/*}*/
/*.modal-backdrop {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  opacity: 1;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/
/*.btn-close {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  border: none;*/
/*  font-size: 20px;*/
/*  padding: 10px;*/
/*  cursor: pointer;*/
/*  font-weight: bold;*/
/*  color: #fff;*/
/*  background: transparent;*/
/*}*/
/*.modal {*/
/*  background: url("../assets/images/site-content/background_brochure.jpg")*/
/*    #06173f;*/
/*  !*opacity: 1;*!*/
/*  background-repeat: no-repeat;*/
/*  background-attachment: fixed;*/
/*  background-position: center;*/
/*  box-shadow: 2px 2px 20px 1px;*/
/*  overflow-x: auto;*/
/*  margin: auto;*/
/*  !*z-index: 1000;*!*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

.loader {
  width: fit-content;
  cursor: wait;
  --gradient-color: rgba(255, 255, 255, 0.5);
}
/* Animation definitions */
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse-x {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.75);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes pulse-y {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
/* Animation classes */
.animation--fade {
  animation: fade 1.5s linear 0.5s infinite;
}
.animation--wave::before {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  background: linear-gradient(
    90deg,
    transparent,
    var(--gradient-color),
    transparent
  );
  animation: wave 1.5s linear 0.5s infinite;
}
.animation--pulse-x {
  animation: pulse-x 1.5s linear 0.5s infinite;
}
.animation--pulse-y {
  animation: pulse-y 1.5s linear 0.5s infinite;
}
.animation--pulse {
  animation: pulse 1.5s linear 0.5s infinite;
}
</style>
