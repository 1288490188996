<template>
  <div class="container pt-3 boatTitle" v-if="yachtbrandInfo.brand.name">
    <h4 class="boatTitle">
      {{ $t("brokerage.about") }} {{ yachtbrandInfo.brand.name }}
      {{ yachtbrandInfo.model }}
      <span v-if="yachtboatName"> - "{{ yachtboatName }}"</span>
    </h4>
    <span class="info-span" v-if="yachtDescription">
      <p class="descrip">{{ yachtDescription.extensiveDescription }}</p>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    yachtDescription: {
      type: Object,
    },
    yachtboatName: {
      type: String,
    },
    yachtbrandInfo: {
      type: Object,
    },
  },
};
</script>
<style scoped>
h4,
.h4 {
  font-size: 20px !important;
  font-weight: 400;
}
.descrip {
  text-align: justify;
  font: inherit;
}
.boatTitle h4 {
  font-weight: 400;
  text-align: left;
}
</style>
