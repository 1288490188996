<script>
import BlockPageHeader from "@/components/BlockPageHeader";
import BlockHalfContentHalfMedia from "@/components/BlockHalfContentHalfMedia.vue";

export default {
  name: "partnerPage",
  components: {
    BlockPageHeader,
    BlockHalfContentHalfMedia,
  },
  data() {
    return {
      imageUrl: "images/site-content/services/header.jpg",
      info: null,
    };
  },
  methods: {},
  created() {
    fetch(
      `${process.env.VUE_APP_STRAPI_API_URL}/partnership-page/?populate[contentPage][populate]=*`
      // `${process.env.VUE_APP_STRAPI_API_URL}/partnership-page/?populate[contentPage][populate]=*&locale=${this.$i18n.locale}`
    )
      .then((res) => res.json())
      .then(
        (data) => (
          (this.info = data), this.eventBus.emit("preHeight")
          //console.log(this.info)
        )
      )
      .catch((err) => console.log(err.message));
  },
};
</script>
<template>
  <!-- Services Start -->
  <BlockPageHeader
    id="start"
    :title="$t('partners.header.title')"
    :description="$t('partners.header.description')"
    :imageurl="imageUrl"
  ></BlockPageHeader>

  <div v-if="this.info">
    <div
      v-for="(item, index) in info.data.attributes.contentPage"
      :key="item.id"
      :index="index"
    >
      <BlockHalfContentHalfMedia
        :title="item.title"
        :description="item.description"
        :imageurl="item.picture.data.attributes.formats.small.url"
        :highlight="['Attribute0', 'Attribute1', 'Attribute2']"
      ></BlockHalfContentHalfMedia>
    </div>
  </div>
</template>
