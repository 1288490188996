<template>
  <meta charset="utf-8" />
  <title>YachtFull International Yachtbrokers</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta name="description" content="" />
  <meta name="keywords" content="" />
  <meta name="author" content="YachtFull" />
  <meta name="website" content="https://yachtfull.com" />
  <meta name="Version" content="v3.8.0" />
  <!-- favicon -->
  <link rel="shortcut icon" href="/favicon.ico" />
</template>
