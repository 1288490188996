<script>
import SnippetVideoPlayer from "@/components/SnippetVideoPlayer.vue";
import SnippetIntroText from "@/components/SnippetIntroText";
import SnippetSidebar from "@/components/SnippetSidebar";
import SnippetBoxYachtCard from "@/components/SnippetBoxYachtCard";
import { ListLoader } from "vue-content-loader";
import ContactForm from "@/components/ContactForm";

export default {
  name: "brokerageYachts",
  components: {
    ContactForm,
    SnippetVideoPlayer,
    SnippetIntroText,
    SnippetSidebar,
    SnippetBoxYachtCard,
    ListLoader,
  },
  async mounted() {
    this.eventBus.on("searchCriteria", (args) => {
      this.constructURL(args);
    });
    this.loadingFilters = true;
    await this.fetchFilters();
    await this.fetchBrands();
    await this.fetchYachts();
    this.loadingFilters = false;
  },
  data() {
    return {
      imageUrl: "images/site-content/about-us/header.jpg",
      videoPoster: "images/video_placeholder.jpg",
      loading: true,
      loadingFilters: true,
      brandNames: null,
      buildYearRange: null,
      errorStatus: null,
      paging: null,
      initialResults: null,
      lengths: null,
      minprice: 0,
      maxprice: 15000000,
      prices: [],
      urlConstruct: "",
      sortType: "length",
      // sortType: "Generic.lengthOverAll",
    };
  },
  methods: {
    onImageLoadError() {
      self.event.target.src = "picture-missing.jpg";
    },
    async fetchFilters() {
      const response = await fetch(
        `${process.env.VUE_APP_STRAPI_API_URL}/yacht-filters/`
      );
      const data = await response.json();
      this.buildYearRange = data[0][0];
      this.prices = data[1][0];
      this.lengths = data[2][0];
    },
    async fetchBrands() {
      const response = await fetch(
        `${process.env.VUE_APP_STRAPI_API_URL}/brands/`
      );
      const brandData = await response.json();
      this.brandNames = brandData;
    },
    constructURL(searchCriteria) {
      // console.log(searchCriteria);
      let whereUsedBoat = "";
      if (searchCriteria.dealership === true) {
        whereUsedBoat = "brokerage=false&dealership=true";
      }
      if (searchCriteria.brokerage === true) {
        whereUsedBoat = "brokerage=true&dealership=false";
      }
      let whereForSale = "";
      if (searchCriteria.forsale === true) {
        whereForSale = "&forsale=true";
      }
      let sortOption = "";
      if (searchCriteria.sort === "default") {
        sortOption = "&sort=default";
        this.sortType = "length";
      }
      if (searchCriteria.sort === "price") {
        sortOption = "&sort=price";
        this.sortType = "price";
      }
      if (searchCriteria.sort === "brand") {
        sortOption = "&sort=brand";
        this.sortType = "brand";
      }
      if (searchCriteria.sort === "length") {
        sortOption = "&sort=length";
        this.sortType = "length";
      }
      if (searchCriteria.sort === "buildyear") {
        sortOption = "&sort=buildyear";
        this.sortType = "buildingYear";
      }
      let minlength = "&lengthmin=0";
      if (searchCriteria.minlength) {
        minlength = "&lengthmin=" + searchCriteria.minlength;
      }
      let maxlength = "&lengthmax=10000";
      if (searchCriteria.maxlength) {
        maxlength = "&lengthmax=" + searchCriteria.maxlength;
      }
      let minprice = "&pricemin=0";
      if (searchCriteria.minprice) {
        minprice = "&pricemin=" + searchCriteria.minprice;
      }
      let maxprice = "&pricemax=50000000";
      if (searchCriteria.maxprice) {
        maxprice = "&pricemax=" + searchCriteria.maxprice;
      }
      let brands = "";
      if (searchCriteria.brands) {
        brands = "&brands=" + searchCriteria.brands;
      }
      let buildyear = "";
      if (searchCriteria.buildyear) {
        buildyear = "&buildyear=" + searchCriteria.buildyear;
      }
      // console.log("minprice=" + searchCriteria.minprice);
      // console.log("maxprice=" + searchCriteria.maxprice);
      this.urlConstruct = `?${whereUsedBoat}${whereForSale}${sortOption}${minprice}${maxprice}${minlength}${maxlength}${brands}${buildyear}`;
      console.log(this.urlConstruct);
      // &forsale=${searchCriteria.forsale}&lengthmin=${searchCriteria.minlength}&lengthmax=${searchCriteria.maxlength}&pricemin=${searchCriteria.minprice}&pricemax=${searchCriteria.maxprice}
      // console.log(
      //   `${process.env.VUE_APP_STRAPI_API_URL}/yacht-search/${this.urlConstruct}`
      // );
      this.fetchYachts();
    },
    groupResultsAndSort(results) {
      let availableYachts = [];
      let newYachts = [];
      let soldYachts = [];
      results.forEach((value) => {
        if (
          value.publishing.sale_status.type === "For Sale" ||
          value.publishing.sale_status.type === "Sale Pending"
        ) {
          if (value.publishing.usedBoat === true) {
            availableYachts.push(value);
            if (this.sortType === "length") {
              availableYachts.sort((a, b) =>
                a.Generic.lengthOverAll < b.Generic.lengthOverAll ? 1 : -1
              );
            }
            if (this.sortType === "buildingYear") {
              availableYachts.sort((a, b) =>
                a.buildingYear < b.buildingYear ? 1 : -1
              );
            }
            if (this.sortType === "price") {
              availableYachts.sort((a, b) => b.price - a.price);
            }
            if (this.sortType === "brand") {
              availableYachts.sort((a, b) =>
                a.Brand.brand.name > b.Brand.brand.name ? 1 : -1
              );
            }
          } else {
            newYachts.push(value);
            if (this.sortType === "length") {
              newYachts.sort((a, b) =>
                a.Generic.lengthOverAll < b.Generic.lengthOverAll ? 1 : -1
              );
            }
            if (this.sortType === "buildingYear") {
              newYachts.sort((a, b) =>
                a.buildingYear < b.buildingYear ? 1 : -1
              );
            }
            if (this.sortType === "price") {
              newYachts.sort((a, b) => b.price - a.price);
            }
            if (this.sortType === "brand") {
              newYachts.sort((a, b) =>
                a.Brand.brand.name > b.Brand.brand.name ? 1 : -1
              );
            }
          }
        } else {
          // if (value.publishing.usedBoat === false) {
          soldYachts.push(value);
          if (this.sortType === "length") {
            soldYachts.sort((a, b) =>
              a.Generic.lengthOverAll < b.Generic.lengthOverAll ? 1 : -1
            );
          }
          if (this.sortType === "buildingYear") {
            soldYachts.sort((a, b) =>
              a.buildingYear < b.buildingYear ? 1 : -1
            );
          }
          if (this.sortType === "price") {
            soldYachts.sort((a, b) => b.price - a.price);
          }
          if (this.sortType === "brand") {
            soldYachts.sort((a, b) =>
              a.Brand.brand.name > b.Brand.brand.name ? 1 : -1
            );
          }
        }
      });
      results = [...availableYachts, ...newYachts, ...soldYachts];
      this.initialResults = results;
    },
    fetchYachts: async function () {
      this.eventBus.emit("loadingSearch", true);
      // console.log(
      //   `${process.env.VUE_APP_STRAPI_API_URL}/yacht-search/${this.urlConstruct}`
      // );
      const response = await fetch(
        `${process.env.VUE_APP_STRAPI_API_URL}/yacht-search/${this.urlConstruct}`,
        { method: "GET" }
      );

      const data = await response.json();
      this.initialResults = data;
      this.groupResultsAndSort(this.initialResults);
      this.loading = false;
      this.eventBus.emit("preHeight");
      this.eventBus.emit("loadingSearch", false);
    },
  },
};
</script>
<template>
  <!--  <div id="preloader" v-if="loading">-->
  <!--    <div id="status">-->
  <!--      <div class="spinner">-->
  <!--        <div class="double-bounce1"></div>-->
  <!--        <div class="double-bounce2"></div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!-- Video Start -->
  <section class="home-slider position-relative">
    <SnippetVideoPlayer
      videoUrlHeader="https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-6-"
      :urlPoster="videoPoster"
    ></SnippetVideoPlayer>
  </section>
  <!-- Video End -->
  <SnippetIntroText
    class="pt-5 mt-2"
    :title="$t('brokerage.introduction.tagline')"
    :text="$t('brokerage.introduction.description')"
  ></SnippetIntroText>

  <section class="section pt-0">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
          style="position: relative"
        >
          <div v-if="loadingFilters" class="col-12">
            <ListLoader></ListLoader>
          </div>
          <div v-else style="position: sticky; top: 130px">
            <SnippetSidebar
              :lengthsSummary="this.lengths"
              :prices="this.prices"
              :buildyearRange="this.buildYearRange"
              :brandNames="this.brandNames"
              :count="this.initialResults.length"
            ></SnippetSidebar>
          </div>
        </div>
        <div class="col-lg-8 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="row">
            <div v-if="loading">
              <div
                class="col-lg-12 col-md-12 col-12"
                v-for="index in 9"
                :key="index"
              >
                <ListLoader></ListLoader>
              </div>
            </div>
            <div v-else-if="errorStatus">
              {{ $t("home.showcase.errorFetch") }}
            </div>
            <div v-else-if="initialResults.length === 0 && loading !== true">
              <div
                class="container n-whitespace"
                v-html="$t(`search.noresults`)"
              ></div>
              <ContactForm class="container"></ContactForm>
            </div>
            <div
              v-else
              class="col-lg-6 col-md-12 col-sm-12 col-12 mt-4 pt-2"
              v-for="(item, index) in initialResults"
              :key="item.id"
            >
              <SnippetBoxYachtCard
                :item="item"
                :index="index"
                :detailsIncluded="true"
              ></SnippetBoxYachtCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
