<script>
import BlockPageHeader from "@/components/BlockPageHeader";
import BlockHalfContentHalfMedia from "../components/BlockHalfContentHalfMedia.vue";

export default {
  name: "aboutNewYachts",
  components: {
    BlockPageHeader,
    BlockHalfContentHalfMedia,
  },
  data() {
    return {
      // imageUrl: "images/site-content/about-us/header.jpg",
    };
  },
  // mounted() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
  // updated() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
};
</script>
<template>
  <!-- About Start -->
  <BlockPageHeader
    id="start"
    :title="$t('new-yachts.header.title')"
    :description="$t('new-yachts.header.description')"
    imageurl="images/site-content/new-yachts/header_new_yachts.jpg"
  ></BlockPageHeader>

  <!-- <BlockSectionHeader
    :title="$t('about-us.header.title')"
    :subtitle="$t('about-us.header.title')"
  ></BlockSectionHeader> -->

  <!--  <BlockHalfContentHalfMedia-->
  <!--    id="sweden-yachts"-->
  <!--    :title="$t('new-yachts.sweden-yachts.title')"-->
  <!--    :description="$t('new-yachts.sweden-yachts.description')"-->
  <!--    imageurl="images/site-content/new-yachts/sweden-yachts.jpg"-->
  <!--    searchString="66"-->
  <!--    :highlight="[-->
  <!--      'Attribute0',-->
  <!--      'Attribute1',-->
  <!--      'Attribute2',-->
  <!--      'Attribute3',-->
  <!--      'Attribute4',-->
  <!--    ]"-->
  <!--  ></BlockHalfContentHalfMedia>-->

  <!--  <BlockHalfContentHalfMedia-->
  <!--    id="malo-yachts"-->
  <!--    imageLeft-->
  <!--    :title="$t('new-yachts.malö-yachts.title')"-->
  <!--    :description="$t('new-yachts.malö-yachts.description')"-->
  <!--    imageurl="images/site-content/new-yachts/malö.jpg"-->
  <!--    searchString="46"-->
  <!--    :highlight="['Attribute2', 'Attribute3', 'Attribute4']"-->
  <!--  ></BlockHalfContentHalfMedia>-->

  <!--  <BlockHalfContentHalfMedia-->
  <!--    id="cr-yachts"-->
  <!--    :title="$t('new-yachts.cr-yachts.title')"-->
  <!--    :description="$t('new-yachts.cr-yachts.description')"-->
  <!--    imageurl="images/site-content/new-yachts/cr-yachts.jpg"-->
  <!--    searchString="21"-->
  <!--    :highlight="[-->
  <!--      'Attribute0',-->
  <!--      'Attribute1',-->
  <!--      'Attribute2',-->
  <!--      'Attribute3',-->
  <!--      'Attribute4',-->
  <!--    ]"-->
  <!--  ></BlockHalfContentHalfMedia>-->

  <BlockHalfContentHalfMedia
    id="bestevaer"
    imageLeft
    :title="$t('new-yachts.bestevaer-pure.title')"
    :description="$t('new-yachts.bestevaer-pure.description')"
    imageurl="images/site-content/new-yachts/bestevaer.jpg"
    searchString="7"
    :highlight="['Attribute0', 'Attribute1', 'Attribute4']"
  ></BlockHalfContentHalfMedia>
</template>
