<template>
  <section
    class="bg-half-260 d-table w-100 bg-animation-left d-flex"
    :style="{ backgroundImage: `url( ${imageUrl} )` }"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-7">
          <div class="title-heading position-relative mt-4" style="z-index: 1">
            <h1 class="heading mb-3" v-html="title"></h1>
            <p class="para-desc">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      imageUrl: this.imageurl,
    };
  },
  props: ["title", "description", "imageurl"],
};
</script>
