<script>
export default {
  data() {
    return {
      imageUrl: this.imageurl,
    };
  },
  props: ["title", "description", "imageurl"],
};
</script>
<template>
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 text-center">
          <div class="section-title ms-lg-4">
            <h4 class="title mb-4 text-center">{{ title }}</h4>
            <p class="text-lighter text-center">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
