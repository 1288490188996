<template>
  <span v-if="conditions">&euro; {{ numberWithCommas(yachtpriceInfo) }} </span>
  <span v-else>{{ $t("brokerage.yacht-card.price-on-request") }}</span>
</template>
<script>
export default {
  props: {
    yachtpublishingInfo: {
      type: Object,
    },
    yachtpriceInfo: {
      type: String,
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    conditions() {
      this.yachtpriceInfo;
      return (
        this.yachtpriceInfo !== null &&
        this.yachtpriceInfo !== "" &&
        this.yachtpublishingInfo.priceVisible !== "No" &&
        this.yachtpriceInfo !== "0" &&
        this.yachtpublishingInfo.sale_status.type !== "Sale Pending" &&
        this.yachtpublishingInfo.sale_status.type !== "Sold"
      );
    },
  },
};
</script>
