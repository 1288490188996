<template>
  <ListLoader v-if="this.loading === true"></ListLoader>
  <div v-else>
    <!--    <pre>{{ sliderUrls }}</pre>-->
    <Splide
      id="videosliderMain"
      :options="options"
      v-if="!loading && !error && sliderUrls.length"
    >
      <SplideSlide v-for="item in sliderUrls" :key="item.id">
        <img :src="item.url" />
      </SplideSlide>
      <SubComponentLabels
        v-if="properties && loading !== true"
        :properties="properties"
        :media="items.primaryPhoto.photo"
      ></SubComponentLabels>
    </Splide>
    <Splide v-else :options="options">
      <SplideSlide>
        <img src="@/assets/images/media_follow_soon.jpg" />
        <SubComponentLabels
          v-if="properties && loading !== true"
          :properties="properties"
        ></SubComponentLabels>
      </SplideSlide>
    </Splide>
  </div>
</template>
<script>
import { ListLoader } from "vue-content-loader";
import axios from "axios";
import { defineComponent } from "vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import { Video } from "@splidejs/splide-extension-video";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
// import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";

import SubComponentLabels from "@/components/subComponentLabels";

export default defineComponent({
  data() {
    return {
      items: null,
      error: null,
      loading: true,
    };
  },
  components: { SubComponentLabels, Splide, SplideSlide, ListLoader },
  async created() {
    try {
      this.loading = true;
      const res = await axios.get(
        `${process.env.VUE_APP_STRAPI_API_URL}/yacht-all-images/${this.$route.params.id}`
      );
      this.items = res.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
      console.log(error);
      this.loading = false;
    }
  },
  computed: {
    sliderUrls() {
      let rawSliderMedia = [];
      if (this.items !== null) {
        let data = this.items;
        try {
          if (data.primaryPhoto.photo !== null) {
            if (data.primaryPhoto.photo.formats.xxlarge) {
              rawSliderMedia.push({
                id: data.primaryPhoto.photo.id,
                url: data.primaryPhoto.photo.formats.xxlarge.url,
              });
            }
            if (
              data.primaryPhoto.photo.formats.xlarge &&
              !data.primaryPhoto.photo.formats.xxlarge
            ) {
              rawSliderMedia.push({
                id: data.primaryPhoto.photo.id,
                url: data.primaryPhoto.photo.formats.xlarge.url,
              });
            }
            if (
              data.primaryPhoto.photo.formats.large &&
              !data.primaryPhoto.photo.formats.xlarge
            ) {
              rawSliderMedia.push({
                id: data.primaryPhoto.photo.id,
                url: data.primaryPhoto.photo.formats.large.url,
              });
            }
            if (
              data.primaryPhoto.photo.formats.medium &&
              !data.primaryPhoto.photo.formats.large
            ) {
              rawSliderMedia.push({
                id: data.primaryPhoto.photo.id,
                url: data.primaryPhoto.photo.formats.medium.url,
              });
            }
            if (
              data.primaryPhoto.photo.formats.small &&
              !data.primaryPhoto.photo.formats.medium
            ) {
              rawSliderMedia.push({
                id: data.primaryPhoto.photo.id,
                url: data.primaryPhoto.photo.formats.small.url,
              });
            }

            if (data.media.Pictures[0].pictures !== null) {
              data.media.Pictures[0].pictures.forEach((value) => {
                if (value.formats.xxlarge) {
                  rawSliderMedia.push({
                    id: value.id,
                    url: value.formats.xxlarge.url,
                  });
                }
                if (value.formats.xlarge && !value.formats.xxlarge) {
                  rawSliderMedia.push({
                    id: value.id,
                    url: value.formats.xlarge.url,
                  });
                }
                if (value.formats.large && !value.formats.xlarge) {
                  rawSliderMedia.push({
                    id: value.id,
                    url: value.formats.large.url,
                  });
                }
                if (value.formats.medium && !value.formats.large) {
                  rawSliderMedia.push({
                    id: value.id,
                    url: value.formats.medium.url,
                  });
                }
                if (value.formats.small && !value.formats.medium) {
                  rawSliderMedia.push({
                    id: value.id,
                    url: value.formats.small.url,
                  });
                }
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      return rawSliderMedia;
    },
  },
  props: {
    nolabels: {
      type: Boolean,
      default: false,
    },
    thumbs: {
      type: Boolean,
    },
    options: {
      type: Object,
    },
    properties: {
      type: Object,
    },
  },
});
</script>
<style>
.splide__slide {
  /*opacity: 0.99;*/
  background: #fff;
}
.splide__slide.is-active {
  opacity: 1;
}
.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 10px 10px;
}
.splide--nav {
  margin-top: 1rem;
}
.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: #ffca2c 2px solid;
  border-radius: 13px 13px 13px 13px;
}
.splide__arrow {
  background-color: #000;
  box-sizing: border-box;
  vertical-align: middle;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.splide__arrow svg {
  box-sizing: border-box;
  stroke: rgb(255, 255, 255);
  cursor: pointer;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
  fill: white;
}

.sale_status {
  top: 40px;
  z-index: 100;
  background-color: #d51a1a;
  opacity: 0.8;
  flex-grow: inherit;
  font-weight: 400;
  position: absolute;
  color: #fff;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 1px 2px 2px black;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  padding: 8px;
}

div .labeltext {
  position: relative;
  left: 0px;
  top: -2px;
  text-align: center;
  padding: 0px;
  font-weight: bold;
  display: inline;
  font-size: 0.5vw;
  margin-left: 15px;
  text-transform: uppercase;
}
</style>
