<script>
import BlockPageHeader from "@/components/BlockPageHeader";
import BlockHalfContentHalfMedia from "../components/BlockHalfContentHalfMedia.vue";

export default {
  name: "aboutServices",
  components: {
    BlockPageHeader,
    BlockHalfContentHalfMedia,
  },
  data() {
    return {
      imageUrl: "images/site-content/about-us/header.jpg",
    };
  },
  // mounted() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
  // updated() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
};
</script>
<template>
  <!-- Services Start -->
  <BlockPageHeader
    id="start"
    :title="$t('services.header.title')"
    :description="$t('services.header.description')"
    :imageurl="imageUrl"
  ></BlockPageHeader>

  <BlockHalfContentHalfMedia
    id="selling-your-yacht"
    imageLeft
    :title="$t('services.selling-your-yacht.title')"
    :description="$t('services.selling-your-yacht.description')"
    imageurl="images/site-content/services/selling-your-yacht.jpg"
    :highlight="['Attribute0', 'Attribute1', 'Attribute2']"
  ></BlockHalfContentHalfMedia>

  <BlockHalfContentHalfMedia
    id="selling-your-yacht-section-2"
    :title="$t('services.selling-your-yacht-2.title')"
    :description="$t('services.selling-your-yacht-2.description')"
    v-bind:carouselUrl="true"
  ></BlockHalfContentHalfMedia>

  <BlockHalfContentHalfMedia
    id="purchase-support"
    imageLeft
    :title="$t('services.purchase-support.title')"
    :description="$t('services.purchase-support.description')"
    imageurl="images/site-content/services/purchase-support.jpg"
    :highlight="['Attribute2', 'Attribute3', 'Attribute4']"
  ></BlockHalfContentHalfMedia>

  <BlockHalfContentHalfMedia
    id="yacht-valuation"
    :title="$t('services.yacht-valuation.title')"
    :description="$t('services.yacht-valuation.description')"
    imageurl="images/site-content/services/report.jpg"
    :highlight="[
      'Attribute0',
      'Attribute1',
      'Attribute2',
      'Attribute3',
      'Attribute4',
    ]"
  ></BlockHalfContentHalfMedia>
</template>
