<script>
import SnippetVideoPlayer from "@/components/SnippetVideoPlayer.vue";
import PageIntroComponent from "@/components/PageIntroComponent";
import ComponentShowcase from "@/components/ComponentShowcase";
import ComponentDealership from "@/components/ComponentDealership";
import ComponentContact from "@/components/ComponentContact";

export default {
  components: {
    SnippetVideoPlayer,
    PageIntroComponent,
    ComponentShowcase,
    ComponentDealership,
    ComponentContact,
  },
  data() {
    return {
      videoPoster: "images/video_placeholder.jpg",
    };
  },
};
</script>

<template>
  <section class="home-slider position-relative">
    <SnippetVideoPlayer
      urlVideo="https://storage.googleapis.com/static.yachtfull.com/homepageLandingVideos/Landing-Yachtfull-3"
      :urlPoster="videoPoster"
    ></SnippetVideoPlayer>
  </section>
  <PageIntroComponent
    :title="$t('home.introduction.title')"
    :description="$t('home.introduction.text')"
    :button="$t('home.introduction.button')"
  ></PageIntroComponent>

  <ComponentShowcase
    :title="$t('home.showcase.title')"
    :description="$t('home.showcase.subtitle')"
    background="grey"
  >
  </ComponentShowcase>

  <ComponentDealership
    :title="$t('home.dealerships.title')"
    :description="$t('home.dealerships.subtitle')"
  >
  </ComponentDealership>

  <ComponentContact
    :title="$t('home.contact.title')"
    :description="$t('home.contact.subtitle')"
  >
  </ComponentContact>
</template>
