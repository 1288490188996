<template>
  <div class="align-items-center mb-4 pb-2">
    <div class="col-lg-12">
      <div class="section-title text-lg-left">
        <h6 class="text-primary text-uppercase">{{ title }}</h6>
        <h4 class="title mb-4 mb-lg-0 text-uppercase">{{ description }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //
    };
  },
  props: ["title", "description"],
};
</script>
