import data from "./dataPass.json";
import en from "@/locales/en.json";

data.en = en;

export const newData = {
  data: data,
};

export const messages = {
  en: en,
};
