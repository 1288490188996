<template>
  <div
    class="sales_status_label"
    :class="
      properties.publishing.sale_status.type === 'For Sale' &&
      properties.publishing.usedBoat !== false
        ? 'forsale'
        : '' || properties.publishing.sale_status.type === 'Sale Pending'
        ? 'salepending'
        : '' || properties.publishing.usedBoat === false
        ? 'badge-custom-build'
        : '' || properties.publishing.sale_status.type === 'Sold'
        ? 'badge-sold-slider'
        : ''
    "
  >
    <span
      v-if="
        properties.publishing.sale_status.type &&
        properties.publishing.usedBoat !== false
      "
      >{{ properties.publishing.sale_status.type }}</span
    >
    <span v-else> {{ $t("brokerage.usedboat.label") }}</span>
  </div>

  <div class="wrapper">
    <div class="flex">
      <div
        class="item brochure"
        v-if="!nolabels"
        @click="showModal('brochure', properties, media)"
      >
        <span>{{ $t("brokerage.buttons.request_brochure") }}</span>
      </div>
      <div
        class="item viewing"
        v-if="!nolabels"
        @click="showModal('viewing', properties, media)"
      >
        <span>{{ $t("brokerage.buttons.schedule_viewing") }}</span>
      </div>

      <div
        class="item vr"
        v-if="
          !nolabels &&
          properties.media &&
          properties.media?.virtualTourID !== null &&
          properties.media?.virtualTourID !== ''
        "
        @click="showModal('vr', properties.media?.virtualTourID)"
      >
        <span>{{ $t("brokerage.watch-360") }}</span>
      </div>
      <div
        class="item video"
        v-if="
          !nolabels &&
          properties.media &&
          properties.media.videoURL !== null &&
          properties.media.videoURL !== ''
        "
        @click="showModal('video', properties.media)"
      >
        <span>{{ $t("brokerage.watch-video") }}</span>
      </div>

  </div>

  </div>
  <div
      class="promobar promo"
      v-if="properties.id === 199"
  >
   <a target="_blank" href="https://palmasuperyachtvillage.com/en/yachts/#yachts-brokerage"><img src="/images/site-content/promotions/boatshow.jpg"></a>
  </div>
</template>
<script>
export default {
  props: {
    nolabels: {
      type: Object,
    },
    properties: {},
    media: {},
  },
  methods: {
    showModal(event, properties, media) {
      this.eventBus.emit("showModal", { event, properties, media });
    },
  },
};
</script>
<style scoped>

.sale_status {
  padding: 25px;

  font-weight: normal;
}
.sale_status .salepending {
  background-color: #f84300;

}
.promobar a {
  /*       v-space   h-space  <---  to set vertical and horizontal space  */
  /*        |         |                                                   */
  z-index: 100;
  opacity: 1;
  flex-grow: inherit;
  margin: 10px 0 0 10px; /* <---- required */
  margin-left: 20px;
  margin-right: 0vw;
  color: #fff;
  padding: 0;
  width: 30vw;
  max-width: 450px;
}
.promo a {
  bottom: 1.3vw; /* This positions the div 30px from the bottom */
  right: 1.3vw; /* This positions the div 30px from the right */
  z-index: 100;
  position: absolute;
  display: flex;
  justify-content: right; /* This will horizontally align the content in the center */
  img {
    width: 65%;
  }
}

.sales_status_label {
  width: 7vw;

  border: #ffffff 1px solid;
  top: 1.2vw;
  font-size: 0.55vw;
  z-index: 100;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1vw;
  flex-grow: 0.81;
  text-align: center;
  display: flex;
  background-color: #6a6d80;
  color: #fff;
}
.label {
  width: 12vw;

  border: #ffffff 1px solid;
  /*height: 30px;*/
  z-index: 100;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  padding-right: 15px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  background-color: #ffc107;
  color: #fff;
  /*border: 0;*/
}
.forsale {
  background-color: #06173f;
  color: #fff;

  text-align: center;
}
.badge-custom-build {
  background-color: #ffca2c;
}

.badge-sold-slider {
  background-color: #ffca2c;
}


div.wrapper {
  width: 12vw; /* <------------- change it or remove to see full effect */
  box-sizing: content-box;

  position: absolute;
  right: 0.3vw;
  top: 0.3vw;
}
div.flex {
  margin: 12px 0 0 12px;
  flex-wrap: wrap; /* <---------- required if we want wrapping for items */
  display: flex; /* <------------ required */
}


div.item {
  z-index: 100;
  text-transform: uppercase;
  opacity: 0.95;
  max-width: 400px;
  flex-grow: inherit;
  text-align: center;
  font-size: 0.45vw;
  margin: 10px 0 0 80px; /* <---- required */
  margin-right: 1vw;
  width: 8vw;
  color: #fff;
  padding: 5px;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 2px 2px #000;

}
.brochure {
  background-color: #06173f;
  color: #fff;
}
.brochure:hover {
  background-color: rgba(6, 23, 63, 0.51);
  cursor: pointer;
}
.viewing {
  background-color: #a7a8aa;
  color: #fff;
}
.viewing:hover {
  background-color: rgba(167, 168, 170, 0.55);
  cursor: pointer;
}
.vr {
  background-color: #007fee;
  color: #fff;
}
.vr:hover {
  background-color: rgba(0, 127, 238, 0.48);
  cursor: pointer;
}
.video {
  background-color: #76777a;
  color: #fff;
}
.video:hover {
  background-color: rgba(118, 119, 122, 0.51);
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Insert your styles for extra small devices here */
  .sales_status_label {
    display: none;
  }
  .item {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* Insert your styles for small devices here */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Insert your styles for medium devices here */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Insert your styles for large devices here */

}

@media only screen and (max-width: 1787px) {
  /* Insert your styles for extra large devices here */

  div.item {
    font-size: 0.6vw;
    margin: 10px 0 0 60px; /* <---- required */
    margin-right: 1vw;
    width: 10vw;
    padding: 5px;
  }
  .sales_status_label {
    width: 7vw;
    padding: 5px;
    font-size: 0.65vw;
    padding-left: 1vw;
    flex-grow: 0.81;
  }
}
@media only screen and (max-width: 1570px) {
  /* Insert your styles for extra large devices here */

  div.item {
    font-size: 0.6vw;
    margin: 10px 0 0 40px; /* <---- required */
    margin-right: 1vw;
    width: 13vw;
    padding: 5px;
  }

}
@media only screen and (max-width: 1186px) {
  /* Insert your styles for extra large devices here */

  div.item {
    font-size: 0.6vw;
    margin: 10px 0 0 20px; /* <---- required */
    margin-right: 1vw;
    width: 13vw;
    padding: 5px;
  }

}
@media only screen and (max-width: 803px) {
  /* Insert your styles for extra large devices here */

  div.item {
    font-size: 0.6vw;
    margin: 10px 0 0 0px; /* <---- required */
    margin-right: 1vw;
    width: 13vw;
    padding: 5px;
  }

}
/* Extra
/* Consider adding a media query for high-resolution devices if you need it */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  /* Insert your high-resolution styles here */
}

</style>
