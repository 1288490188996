<script>
import SocialIcons from "@/components/SnippetSocialIcons.vue";

export default {
  components: { SocialIcons },
};
</script>
<template>
  <div id="topbar" style="height: 40px">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <SocialIcons v-once></SocialIcons>
        </div>
        <!--        <div class="col-5 pull-right hide-sm">-->
        <!--          <SnippetSelectLocale class="language_switcher"></SnippetSelectLocale>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<style scoped>
.language_switcher {
  margin: 0;
  margin-top: 6px;
  padding-left: 5px;
  width: 50px;
  border-radius: 14px;
  color: #122348 !important;
  box-shadow: 0 3px 5px 0 rgba(6, 23, 63, 0.3);
}
.pull-right {
  text-align: right;
}
@media screen and (max-width: 600px) {
  .language_switcher {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
