import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import router from "./router";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import { Plugin } from "vue-responsive-video-background-player";
import mitt from "mitt";
import vueVimeoPlayer from "vue-vimeo-player";
import VueLazyLoad from "vue3-lazyload";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import axios from "axios";
import VueAxios from "vue-axios";

const eventBus = mitt();
import { createI18n } from "vue-i18n";
// import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";

import en from "./locales/en.json";
// import nl from "./locales/nl.json";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* add icons to the library */
library.add(fas, fab);

/* CSS Block */
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/style.css";
import "@/assets/css/colors/default.css";
import "@/assets/css/additionalStyles.css";

/* end CSS Block */
const i18n = createI18n({
  // something vue-i18n options here ...
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale,
  localeDir: "locales",
  legacy: true,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    en,
    // nl,
  },
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: "i18n_redirected",
    redirectOn: "root", // recommended
  },
});

const app = createApp(App, {
  // Listen for the 'expand' event
  onExpand() {
    console.log("expand");
  },
});
const head = createHead();

app.config.globalProperties.eventBus = eventBus;
app.use(router);
app.use(i18n);
app.use(head);
app.use(VueLazyLoad);
app.use(ElementPlus);
app.use(VueAxios, axios);
app.use(vueVimeoPlayer);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(
  VueGtag,
  {
    config: {
      // id: "UA-96471268-1",
      id: "G-V83ML0XJC1",
      // router: router,
      params: {
        anonymize_ip: true,
        send_page_view: true,
      },
    },
  },
  router
);
app.use(Plugin);
app.mount("#app");
