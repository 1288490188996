<template>
  <template v-if="headState">
    <template v-if="previousHeader != undefined && typeof header == 'number'">
      <el-row class="headData">
        <span class="title-span font-weight-light text-200 subheader">
          {{
            sRemover(getTitleCase(previousHeader)) +
            " " +
            (header + 1).toString()
          }}
        </span>
      </el-row>
    </template>
    <template v-else>
      <el-row class="headData" v-if="previousHeader != header">
        <span
          class="group-title"
          v-if="
            header === 'generator' ||
            header === 'batCharger' ||
            header === 'inverter' ||
            header === 'engineInfo' ||
            header === 'Engine' ||
            header === 'additionalSails'
          "
        >
        </span>
        <span v-else class="title-span font-weight-light text-200 subheader">
          {{
            $t("brokerage.specs.specTabs." + header.toString()) ==
            "brokerage.specs.specTabs." + header.toString()
              ? getTitleCase(header)
              : $t("brokerage.specs.specTabs." + header.toString())
          }}
        </span>
      </el-row>
    </template>
  </template>
  <template v-if="typeof object == 'object'">
    <template v-for="(itemx, keyx) in object" :key="keyx">
      <template v-if="typeof itemx == 'object'">
        <template v-if="!Array.isArray(itemx)">
          <template v-if="Object.keys(itemx).length > 1">
            <FilterComponent
              :object="itemx"
              :header="keyx"
              :previousHeader="header"
              :headState="true"
              :paddingTop="paddingTop"
            />
          </template>
          <template v-else>
            <template v-if="typeof itemx[Object.keys(itemx)[0]] != 'object'">
              <el-row>
                <template v-if="typeof keyx != 'number'">
                  <el-col :span="12">
                    <label class="title-span">
                      {{
                        $t(
                          "brokerage.specs.specsProperties." + keyx.toString()
                        ) ==
                        "brokerage.specs.specsProperties." + keyx.toString()
                          ? getTitleCase(keyx)
                          : $t(
                              "brokerage.specs.specsProperties." +
                                keyx.toString()
                            )
                      }}
                    </label>
                  </el-col>
                  <el-col :span="12" class="info-span">
                    {{ itemx[Object.keys(itemx)[0]] }}
                  </el-col>
                </template>
              </el-row>
            </template>
            <template v-else>
              <FilterComponent
                :object="itemx"
                :header="keyx"
                :previousHeader="header"
                :headState="false"
                :paddingTop="paddingTop"
              />
            </template>
          </template>
        </template>
        <template v-else>
          <FilterComponent
            :object="itemx"
            :header="keyx"
            :previousHeader="header"
            :headState="true"
            :paddingTop="paddingTop"
          />
        </template>
      </template>
      <template v-else>
        <el-row :class="paddingTop.includes(keyx) ? 'spaceData' : 'parData'">
          <el-col :span="12">
            <label class="title-span">
              {{
                $t("brokerage.specs.specsProperties." + keyx.toString()) ==
                "brokerage.specs.specsProperties." + keyx.toString()
                  ? getTitleCase(keyx)
                  : $t("brokerage.specs.specsProperties." + keyx.toString())
              }}
            </label>
          </el-col>
          <el-col :span="12" class="info-span">
            {{ itemx }}
          </el-col>
        </el-row>
      </template>
    </template>
  </template>
</template>

<script>
import FilterComponent from "@/components/CBoatSpecFilter";
import { messages } from "@/data/data";

export default {
  name: "FilterComponent",
  props: ["object", "header", "previousHeader", "headState", "paddingTop"],
  components: {
    FilterComponent,
  },
  data() {
    return {
      en: messages.en,
    };
  },
  computed: {},
  mounted() {
    // console.log(this.en)
    // console.log(this.en)
    // console.log(this.findPropFromObj(this.en,"search"))
  },
  setup() {},
  methods: {
    show(objectS) {
      if (Array.isArray(objectS)) {
        objectS.forEach((s, key) => {
          objectS[key] = this.sortObject(s);
        });
      }
      return objectS;
    },
    testing(object, value) {
      // console.log(object,value)
      // let x = this.findPropFromObj(object,value)
      // console.log(x)
      return value;
    },
    makeAString(value) {
      let number = 40 * value;
      number = number + "px";
      return number;
    },
    getTitleCase(input) {
      if (typeof input == "string") {
        let stringNew = input.toString();
        let result = stringNew.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
        return result.charAt(0).toUpperCase() + result.slice(1);
      } else {
        let newInput = input + 1;
        return newInput;
      }
    },
    sRemover(input) {
      input = input.toString();
      if (input.endsWith("s")) {
        return input.slice(0, -1);
      } else {
        return input;
      }
    },
    findPropFromObj(object, key) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        return object[key];
      }
      for (const k of Object.keys(object)) {
        if (typeof object[k] === "object") {
          const o = this.findPropFromObj(object[k], key);
          if (o !== null && typeof o !== "undefined" && typeof o !== "object") {
            return o;
          }
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.parData {
  margin-bottom: 0.1rem;
  border-bottom: 1px solid #f5f5f5;
}
.el-col-12 {
  margin-bottom: 0.1rem;
}
h2 {
  font-size: 10px;
  font-weight: 400;
}

h3 {
  font-size: 14pt;
  font-weight: 400;
}
.group-title {
  font-size: 14pt;
  font-weight: 400;
  margin-top: 1.7rem;
}
.group-sub-title {
  font-size: 12pt;
  font-weight: 300;
}
.headData {
  margin-bottom: 0.1rem;
  font-weight: 400;
}
.spaceData {
  margin-bottom: 2rem;
}
.subheader {
  font-size: 16px !important;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 10px;
}
</style>
