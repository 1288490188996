<script>
import BlockPageHeader from "@/components/BlockPageHeader";
import BlockHalfContentHalfMedia from "../components/BlockHalfContentHalfMedia.vue";

export default {
  name: "aboutUs",
  components: {
    BlockPageHeader,
    BlockHalfContentHalfMedia,
  },
  data() {
    return {
      imageUrl: "images/site-content/about-us/header.jpg",
    };
  },
  // mounted() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  //   this.$gtag.event("login", { method: "Google" });
  // },
  // updated() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
};
</script>
<template>
  <!-- Services Start -->
  <BlockPageHeader
    id="start"
    :title="$t('about-us.header.title')"
    :description="$t('about-us.header.description')"
    :imageurl="imageUrl"
  ></BlockPageHeader>

  <BlockHalfContentHalfMedia
    id="location"
    imageLeft
    :title="$t('about-us.location.title')"
    :description="
      $t('about-us.location.description', {
        br: '<br />',
        strong: '<strong>',
        estrong: '</strong>',
      })
    "
    imageurl="images/site-content/about-us/history.jpg"
  ></BlockHalfContentHalfMedia>

  <BlockHalfContentHalfMedia
    id="impression"
    :title="$t('about-us.impression.title')"
    :description="
      $t('about-us.impression.description', {
        br: '<br/>',
        strong: '<b>',
        estrong: '</b>',
      })
    "
    carouselUrl
  ></BlockHalfContentHalfMedia>
</template>
