<template>
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="section-title mb-2 pb-2 pl-4 pr-4">
          <h4
            class="title mb-2 text-uppercase text-center col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            {{ title }}
            <span v-if="this.resultCount && this.resultCounterEnable === 'true'"
              >({{ resultCount }})</span
            >
          </h4>
          <p
            class="text-lighter mb-0 mx-auto text-center col-lg-10 col-md-12 col-sm-12 col-xs-12"
            v-html="text"
          ></p>
        </div>
        <div class="col-lg-12 text-center col-md-4 mt-2 pt-2" v-if="actionUrl">
          <a :href="actionUrl" class="btn btn-primary">{{ button }}</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import { bus } from '@/main';

export default {
  data() {
    return {
      resultCount: null,
      resultCounterEnable: process.env.VUE_APP_show_result_count,
    };
  },
  created() {
    // bus.$on('resultCount', (data) => {
    //   this.resultCount = data;
    // })
  },
  props: ["title", "text", "button", "actionUrl"],
};
</script>
