<script>
import SubComponentDraft from "@/components/subComponentDraft";
import SubComponentPrice from "@/components/subComponentPrice";

export default {
  components: { SubComponentPrice, SubComponentDraft },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ".");
    },
  },
  props: {
    detailsIncluded: {
      type: Boolean,
      default: false,
    },
    positions: {},
    item: {},
    index: {},
  },
  computed: {
    titleOfCard() {
      let charCount;
      let titleString;
      let brandname = "";
      let brandmodel = "";
      let boatname = "";

      if (this.item.Brand.brand.name) {
        brandname = this.item.Brand.brand.name;
      }
      if (this.item.Brand.model) {
        brandmodel = this.item.Brand.model;
      }
      if (this.item.boatName) {
        boatname = this.item.boatName;
      }
      charCount = brandname.length + brandmodel.length + boatname.length;
      if (charCount > 46) {
        titleString = brandname + " " + brandmodel;
      }
      if (charCount <= 46) {
        titleString = brandname + " " + brandmodel + " " + boatname;
      }

      return titleString;
    },
    boatNameConstruct() {
      let nameString;
      if (this.item.boatName) {
        nameString = '"' + this.item.boatName + '"';
      } else {
        nameString = "";
      }
      return nameString;
    },
  },
};
</script>
<style>
.properties {
  width: 100%;
  display: inline;
}
.propertyTitles {
  width: 50%;
  padding-bottom: 3px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.63);
  font-size: 0.88em;
}
.propertyValues {
  width: 50%;
  padding-bottom: 3px;
  font-size: 0.88em;
  color: rgba(26, 26, 26, 0.63);
  font-weight: 300;
}
.badge-custom-build {
  background-color: #ffca2c;
}
.thumbFormat {
  width: 100%;
  aspect-ratio: 4 / 3;
  -o-object-fit: cover;
  object-fit: cover;
}
</style>
<template>
  <div class="card job-box rounded shadow border-0 overflow-hidden">
    <div class="border-bottom">
      <div class="position-relative">

        <ul class="list-unstyled head mb-0" v-if="item.publishing.sale_status">
          <li
            class="badge badge-pill"
            :class="
              item.publishing.sale_status.type === 'For Sale' &&
              item.publishing.usedBoat !== false
                ? 'badge-primary'
                : '' || item.publishing.sale_status.type === 'Sale Pending'
                ? 'badge-warning'
                : '' || item.publishing.usedBoat === false
                ? 'badge-custom-build'
                : '' || item.publishing.sale_status.type === 'Sold'
                ? 'badge-sold'
                : ''
            "
          >
            <span v-if="item.publishing.usedBoat !== false">
              {{ item.publishing.sale_status.type }}
            </span>
            <!--            -->
            <!--            item.publishing.sale_status.type === 'For Sale' &&-->
            <span v-else>{{ $t("brokerage.usedboat.label") }}</span>
          </li>
        </ul>
        <a :href="`/brokerage/` + item.id">
          <img
            v-if="
              item.primaryPhoto?.photo?.formats?.small?.url &&
              item.primaryPhoto !== null
            "
            class="thumbFormat"
            v-lazy="{
              src: item.primaryPhoto.photo.formats.small.url,
              delay: 200,
            }"
          />
          <img
            v-else-if="item.primaryPhoto === null"
            class="thumbFormat"
            src="@/assets/images/media_follow_soon.jpg"
          />
          <img
            v-else
            class="thumbFormat"
            src="@/assets/images/media_follow_soon.jpg"
          />
        </a>
        <div class="bg-white"></div>
      </div>
    </div>
    <div class="card-body content position-relative">
      <div class="firm-logo rounded-circle shadow bg-light text-center">
        <img
          src="/images/YachtFull-avatar-white.png"
          class="avatar avatar-md-sm"
          alt=""
        />
      </div>
      <div class="company-detail text-left mt-3">
        <h5 class="mb-0 title">
          <span v-if="item.Brand && item.Brand.brand !== null">{{
            titleOfCard
          }}</span>
        </h5>
        <p class="text-300 text-black-50">
          {{ $t("brokerage.yacht-card.buildyear") }}
          <span class="text-400">
            {{ item.buildingYear }}
          </span>
        </p>
      </div>
      <div v-if="this.detailsIncluded" class="list-unstyled">
        <div class="row">
          <div class="propertyTitles col-6">
            {{ $t("brokerage.yacht-card.dimensions") }} (in m)
          </div>
          <div class="propertyValues col-6">
            {{ numberWithCommas(item.Generic.lengthOverAll) }} x
            {{ numberWithCommas(item.Generic.beam) }} x
            <sub-component-draft
              :yachtgenericInfo="item.Generic"
            ></sub-component-draft>
          </div>
        </div>

        <div class="row">
          <div class="propertyTitles col-6">
            {{ $t("brokerage.yacht-card.hull-material") }}
          </div>
          <div
            class="propertyValues col-6"
            v-if="item.hullMaterial?.hullMaterial?.material"
          >
            {{ item.hullMaterial.hullMaterial.material }}
          </div>
          <div v-else>&nbsp;</div>
        </div>
        <div v-if="item.accomodation && item.accomodation.areasSummary">
          <div class="row" v-if="item.accomodation?.areasSummary.cabinsInTotal">
            <div class="propertyTitles col-6">
              {{ $t("brokerage.yacht-card.cabins") }}
            </div>
            <div class="propertyValues col-6">
              {{ item.accomodation?.areasSummary.cabinsInTotal }}
            </div>
          </div>

          <div class="row" v-if="item.accomodation?.areasSummary.berthsInTotal">
            <div class="propertyTitles col-6">
              {{ $t("brokerage.yacht-card.berths") }}
            </div>
            <div class="propertyValues col-6">
              {{ item.accomodation?.areasSummary.berthsInTotal }}
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="propertyTitles col-6">
            {{ $t("brokerage.yacht-card.price") }}
          </div>
          <div class="propertyValues col-6">
            <sub-component-price
              :yachtpriceInfo="item.price"
              :yachtpublishingInfo="item.publishing"
            ></sub-component-price>
          </div>
        </div>
      </div>
      <a
        v-if="this.detailsIncluded"
        :href="`/brokerage/${item.id}`"
        class="btn btn-outline-primary text-lighter btn-block"
        >{{ $t("brokerage.yacht-card.button-text") }}</a
      >
    </div>
  </div>
</template>
