<template>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="media contact-detail mt-3" v-if="langLocationID">
        <div class="icon">
          <font-awesome-icon
            :icon="['fas', 'envelope']"
            class="fea icon-m-md text-dark mr-3"
          />
        </div>
        <div class="media-body content">
          <h4 class="title mb-0">
            {{ $t("contact.page.email.title") }}
          </h4>
          <a :href="`mailto:${emailAddress}`" class="text-primary">{{
            emailAddress
          }}</a>
        </div>
      </div>
      <div class="media contact-detail mt-3">
        <div class="icon">
          <font-awesome-icon
            :icon="['fas', 'phone']"
            class="fea icon-m-md text-dark mr-3"
          />
        </div>
        <div class="media-body content">
          <h4 class="title font-weight-bold mb-0">
            {{ $t("contact.page.phone.title") }}
          </h4>
          <a
            :href="$t(`company.location` + langLocationID + `.phonevalue`)"
            class="text-primary"
          >
            {{ $t(`company.location` + langLocationID + `.phone`) }}
          </a>
        </div>
      </div>
      <div class="media contact-detail mt-3">
        <div class="icon">
          <font-awesome-icon
            :icon="['fab', 'whatsapp']"
            class="fea icon-m-md text-dark mr-3"
          />
        </div>
        <div class="media-body content">
          <h4 class="title font-weight-bold mb-0">
            {{ $t("contact.page.whatsapp.title") }}
          </h4>
          <a
            :href="$t(`company.location` + langLocationID + `.whatsappvalue`)"
            target="_blank"
            class="text-primary"
            >{{ $t(`company.location` + langLocationID + `.whatsapp`) }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="media contact-detail mt-3">
        <div class="icon">
          <font-awesome-icon
            :icon="['fas', 'map']"
            class="fea icon-m-md text-dark mr-3"
          />
        </div>
        <div class="media-body content" v-if="!langLocationID">
          <a href="/company" class="video-play-icon text-primary">{{
            $t("contact.location.company_page_link")
          }}</a>
        </div>
        <div class="media-body content">
          <h4 class="title font-weight-bold mb-0" v-if="langLocationID">
            {{ $t("contact.page.location.title") }}
          </h4>
          <a
            href="javascript:void(0)"
            class="video-play-icon text-primary"
            v-if="langLocationID === 1"
            @click="showModal('elburg')"
            >{{ $t("contact.page.location.value") }}</a
          >
          <a
            href="javascript:void(0)"
            class="video-play-icon text-primary"
            v-if="langLocationID === 2"
            @click="showModal('livorno')"
            >{{ $t("contact.page.location.value") }}</a
          >
        </div>
      </div>
      <div class="media contact-detail mt-3">
        <div class="icon" v-if="langLocationID">
          <font-awesome-icon
            :icon="['fas', 'map-marker-alt']"
            class="fea icon-m-md text-dark mr-3"
          />
        </div>
        <div class="media-body content" v-if="langLocationID">
          <h4 class="title font-weight-bold mb-0">
            {{ $t("contact.page.address") }}
          </h4>
          <div
            v-if="langLocationID === 1"
            v-html="$t('footer.contact_us_address', { br: '<br/>' })"
          ></div>
          <div
            v-if="langLocationID === 2"
            v-html="$t('footer.contact_us_address_2', { br: '<br/>' })"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDetails",
  props: {
    place: {
      type: String,
    },
    langLocationID: {
      type: Number,
      default: 1,
    },
    contactInfo: {
      type: Object,
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    emailAddress() {
      if (this.langLocationID === 1) {
        return "info@yachtfull.com";
      } else if (this.langLocationID === 2) {
        return "fabio@yachtfull.com";
      }
    },
  },

  methods: {
    // showModal($event) {
    //   this.eventBus.emit("showModal", $event);
    // },
    showModal(event, properties, media) {
      this.eventBus.emit("showModal", { event, properties, media });
    },
  },
};
</script>

<style scoped>
.icon {
  vertical-align: top;
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.title {
  font-size: 17px !important;
  font-weight: bold;
}
.media-body {
  font-size: 18px;
  margin-left: 20px;
}
a.text-primary {
  font-size: 18px;
  font-weight: normal;
}
</style>
