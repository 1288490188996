<script>
import SocialIcons from "./SnippetSocialIcons.vue";
export default {
  components: { SocialIcons },
};
</script>
<style scoped>
#root {
  white-space: pre-line;
}
</style>
<template>
  <div class="container" v-once>
    <div class="row">
      <div class="col-12">
        <div class="footer pt-5">
          <div class="row">
            <div class="col-lg-5 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <a href="#" class="logo-footer">
                <img
                  src="@/assets/images/yachtfull-logo-blue-bg-no-brands.svg"
                  height="55"
                  alt=""
                />
              </a>
              <p class="mt-4 text-lighter">
                {{ $t("footer.description_text") }}
              </p>
              <SocialIcons v-once></SocialIcons>
            </div>
            <div class="col-lg-1 col-md-12 mb-0 mb-md-4 pb-0 pb-md-2">
              &nbsp;
            </div>

            <div class="col-lg-3 col-md-12 mb-0 mb-md-4 pb-0 pb-md-2 mb-4">
              <h4 class="text-light footer-head">
                {{ $t("footer.generic_title") }}
              </h4>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <router-link to="/privacy-statement#start" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.privacy_link") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/cookie-statement#start" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.cookie_link") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/company#start" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.company_link") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/terms#start" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.terms_conditions") }}</router-link
                  >
                </li>
              </ul>
              <hr width="50%" />
              <h4 class="text-light footer-head">
                {{ $t("footer.services_title") }}
              </h4>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <router-link
                    to="/services#selling-your-yacht"
                    class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.selling_your_yacht") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/services#yacht-valuation" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.yacht_valuation") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/services#purchase-support" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.search_support") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/newyachts" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    {{ $t("footer.dealership") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <h4 class="text-light footer-head">
                {{ $t("footer.contact_us_title") }}
              </h4>

              <p
                class="mt-4 n-whitespace"
                style=""
                v-html="$t('footer.contact_us_address', { br: '<br />' })"
              ></p>
              <hr />
              <p
                class="mt-0 n-whitespace"
                v-html="$t('footer.contact_us_address_2', { br: '<br />' })"
              ></p>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="footer-py-30 footer-bar">
    <div class="container text-center">
      <div class="row align-items-center">
        <div class="col-sm-12">
          <div class="text-sm-start">
            <p class="mb-0">{{ $t("footer.copyright_text") }}</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </div>
</template>
