<template>
  <div v-if="Object.keys(filteredApi).length !== 0">
    <span class="min-vh-100">
      <el-tabs v-model="activeName" tab-position="top" class="tab-pane">
        <el-tab-pane
          :class="blur === true ? 'blur' : ''"
          v-for="(sort, index) in filteredApi"
          :key="index"
          :label="
            $t('brokerage.specs.specTabs.' + index.toString()) ==
            'brokerage.specs.specTabs.' + index.toString()
              ? getTitleCase(index)
              : $t('brokerage.specs.specTabs.' + index.toString())
          "
          :name="index"
        >
          <FilterComponent
            :object="sort"
            :header="index"
            :headState="true"
            :paddingTop="paddingTop"
          />
        </el-tab-pane>
      </el-tabs>
    </span>
  </div>
  <div class="spanDisclaimer" :class="blur === true ? 'blur' : ''">
    <span class="disclaimer" v-html="$t('brokerage.disclaimerTab')"></span>
  </div>
</template>

<script>
import { newData } from "@/data/data.js";
import FilterComponent from "@/components/CBoatSpecFilter";
// Only import from `/dev` where you import `setupCache`.
// import { setupCache } from 'axios-cache-interceptor';

export default {
  name: "HomeView",
  components: {
    FilterComponent,
  },

  props: {
    blur: {
      type: Boolean,
    },
  },
  data() {
    return newData.data;
  },
  mounted() {
    this.callAPI(this.$route.params.id);
  },
  watch: {
    default(newVal) {
      // console.log(newVal)
      this.callAPI(newVal);
    },
    // activeName(){
    //   // console.log(newVal)
    // }
  },
  methods: {
    callAPI(idNumber) {
      this.axios
        .get(
          `${process.env.VUE_APP_STRAPI_API_URL}/yacht-one-extendedinfo/${idNumber}`
        )
        .then((response) => {
          this.api = { ...response.data };
          // console.log(this.api)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    makeAString(value) {
      let number = 40 * value;
      if (number < 300) number = 300;
      number = number + "px";
      return number;
    },
    getTitleCase(string) {
      var result = string.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },

    makeADecimal(number, divideBy, minFraction) {
      let newNumber;

      if (divideBy) {
        newNumber = number / divideBy;
      } else {
        newNumber = number;
      }

      // Format with thousands separator
      newNumber = newNumber.toLocaleString("en");

      // Handle decimal places
      if (minFraction) {
        newNumber = parseFloat(newNumber).toLocaleString("en", {
          useGrouping: true,
          groupingSeparator: ".",
          minimumFractionDigits: minFraction,
        });
      }

      return newNumber;
    },
    // makeADecimal(number, divideBy, minFraction) {
    //   let newNumber;
    //
    //   if (divideBy) {
    //     newNumber = number / divideBy;
    //   } else {
    //     newNumber = number;
    //   }
    //   // Format with separator
    //   newNumber = parseFloat(newNumber).toLocaleString("es", {
    //     // useGrouping: true,
    //     // groupingSeparator: ".",
    //     minimumFractionDigits: minFraction,
    //   });
    //
    //   // Handle decimals
    //   if (minFraction) {
    //     newNumber = parseFloat(newNumber).toFixed(minFraction);
    //   }
    //
    //   return newNumber;
    // },
    // makeADecimal(number, divideBy, minFraction) {
    //   // let newNumber = number/Math.pow(10,decimalPlace)
    //   let newNumber;
    //   if (divideBy) {
    //     newNumber = number / divideBy;
    //   } else {
    //     newNumber = number;
    //   }
    //   let releaseNumber = newNumber.toLocaleString("en", {
    //     useGrouping: false,
    //     minimumFractionDigits: minFraction,
    //   });
    //   return releaseNumber;
    //   // return newNumber;
    // },
    makeNewString(item, customPoints) {
      // item
      // toAdd
      // console.log(item.substring(0,customPoints[0][1]),customPoints)
      let newString = item;
      // item
      customPoints.forEach((points) => {
        // console.log(item.substring(0,points[1])+points[0])
        // console.log(points)
        newString =
          newString.substring(0, points[1]) +
          points[0] +
          newString.substring(points[1]);
        // console.log(newString)
      });
      return newString;
    },
    removeNull(object) {
      var test = Object.keys(object).forEach((key) => {
        if (object[key] === null) {
          delete object[key];
        }
      });
      // console.log(test)
      return test;
    },
    getAPI(info) {
      this.default = info ? this.default + 1 : this.default - 1;
    },
    filterNull(filter) {
      // console.log(filter)
      var newFilter = Object.entries(filter).forEach(([k, v]) => {
        if (v && typeof v === "object") {
          this.filterNull(v);
        }
        if (
          (v && typeof v === "object" && !Object.keys(v).length) ||
          v === null ||
          v === undefined
        ) {
          if (Array.isArray(filter)) {
            filter.splice(k, 1);
          } else {
            delete filter[k];
          }
        }
      });

      return newFilter;
    },
    removeEmptyElements(obj) {
      if (Array.isArray(obj)) {
        obj.forEach((element, index) =>
          obj.splice(index, 1, this.removeEmptyElements(element))
        );
        return obj;
      }
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([, v]) =>
            Array.isArray(v)
              ? v.length !== 0
              : v !== null && v !== "" && v !== undefined
          )
          .map(([k, v]) => [
            k,
            v === Object(v) ? this.removeEmptyElements(v) : v,
          ])
      );
    },
    removeKeys(obj, keys) {
      return obj !== Object(obj)
        ? obj
        : Array.isArray(obj)
        ? obj.map((item) => this.removeKeys(item, keys))
        : Object.keys(obj)
            .filter((k) => !keys.includes(k))
            .reduce(
              (acc, x) =>
                Object.assign(acc, { [x]: this.removeKeys(obj[x], keys) }),
              {}
            );
    },
    findPropFromObj(object, key) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        return object[key];
      }
      for (const k of Object.keys(object)) {
        if (typeof object[k] === "object") {
          const o = this.findPropFromObj(object[k], key);
          if (o !== null && typeof o !== "undefined") {
            return o;
          }
        }
      }
      return null;
    },
    clearEmpties(o) {
      for (var k in o) {
        if (!o[k] || typeof o[k] !== "object") {
          continue; // If null or not an object, skip to the next iteration
        }

        // The property is an object
        this.clearEmpties(o[k]); // <-- Make a recursive call on the nested object
        if (Object.keys(o[k]).length === 0) {
          delete o[k]; // The object had no properties, so delete that property
        }
      }
      return o;
    },
    convertValue(item, value, filter) {
      if (value == null || value == undefined) return "";

      let isConvertable = this.conversions.find(
        (conversion) => conversion.mainKey == item
      );
      if (isConvertable) {
        var givenText;
        if (isConvertable.type == "number") {
          if (isConvertable.minFractionDigits) {
            givenText = this.makeADecimal(
              value,
              isConvertable.divideBy,
              isConvertable.minFractionDigits
            ).toString();
            if (isConvertable.customPoints) {
              givenText = this.makeNewString(
                givenText,
                isConvertable.customPoints
              );
            }
          } else {
            givenText = this.makeADecimal(
              value,
              isConvertable.divideBy
            ).toString();
            if (isConvertable.customPoints) {
              givenText = this.makeNewString(
                givenText,
                isConvertable.customPoints
              );
            }
          }
        } else if (isConvertable.type == "boolean") {
          //put variable here
          // givenText = value
          if (filter) {
            var variableCheck = this.findPropFromObj(filter, item);
            // givenText = variableCheck
            let combinedElements = [];
            if (variableCheck) {
              isConvertable.ifTrue.forEach((tru) => {
                let prop;
                if (isConvertable.divideBy) {
                  prop = this.findPropFromObj(filter, tru);
                  if (isConvertable.minFractionDigits) {
                    prop = this.makeADecimal(
                      prop,
                      isConvertable.divideBy,
                      isConvertable.minFractionDigits
                    );
                    if (isConvertable.customPoints) {
                      prop = this.makeNewString(
                        prop,
                        isConvertable.customPoints
                      );
                    }
                  } else {
                    prop = this.makeADecimal(prop, isConvertable.divideBy);
                    if (isConvertable.customPoints) {
                      prop = this.makeNewString(
                        prop,
                        isConvertable.customPoints
                      );
                    }
                  }
                } else {
                  prop = this.findPropFromObj(filter, tru);
                }
                combinedElements.push(prop);
              });
              givenText = combinedElements.join(isConvertable.trueSeparator);
            } else {
              isConvertable.ifFalse.forEach((fal) => {
                // let prop = this.findPropFromObj(filter,fal)
                let prop;
                if (isConvertable.divideBy) {
                  prop = this.findPropFromObj(filter, fal);
                  if (isConvertable.minFractionDigits) {
                    prop = this.makeADecimal(
                      prop,
                      isConvertable.divideBy,
                      isConvertable.minFractionDigits
                    );
                    if (isConvertable.customPoints) {
                      prop = this.makeNewString(
                        prop,
                        isConvertable.customPoints
                      );
                    }
                  } else {
                    prop = this.makeADecimal(prop, isConvertable.divideBy);
                    if (isConvertable.customPoints) {
                      prop = this.makeNewString(
                        prop,
                        isConvertable.customPoints
                      );
                    }
                  }
                } else {
                  prop = this.findPropFromObj(filter, fal);
                }
                combinedElements.push(prop);
              });
              givenText = combinedElements.join(isConvertable.falseSeparator);
            }
          }
        } else {
          givenText = value;
        }
        if (isConvertable.preLabel) {
          givenText = isConvertable.preLabel + givenText;
        }
        if (isConvertable.postLabel) {
          givenText = givenText + isConvertable.postLabel;
        }
        if (variableCheck) {
          return {
            isConvertable: true,
            value: givenText,
            keyChange: isConvertable.changeKeyTrue,
          };
        }
        return {
          isConvertable: true,
          value: givenText,
          keyChange: isConvertable.changeKeyFalse,
        };
      } else {
        return { isConvertable: false, value: value };
      }
    },
    sortManual(prevObject, prevArray) {
      if (Array.isArray(prevObject)) {
        let newArray = [];
        prevObject.forEach((prev) => {
          let newObject = {};
          let prevObjectArray = Object.keys(prev).filter(
            (val) => !prevArray.includes(val)
          ); //get keys of object
          prevArray.forEach((newGiven) => {
            let holdNewObject = this.convertValue(newGiven, prev[newGiven]);
            if (holdNewObject.isConvertable) {
              //
              if (holdNewObject.keyChange) {
                newObject[holdNewObject.keyChange] = holdNewObject.value;
              } else {
                newObject[newGiven] = holdNewObject.value;
              }
            } else {
              newObject[newGiven] = holdNewObject.value;
            }
          });
          prevObjectArray.forEach((newGiven) => {
            let holdNewObject = this.convertValue(newGiven, prev[newGiven]);
            if (holdNewObject.isConvertable) {
              //
              if (holdNewObject.keyChange) {
                newObject[holdNewObject.keyChange] = holdNewObject.value;
              } else {
                newObject[newGiven] = holdNewObject.value;
              }
            } else {
              newObject[newGiven] = holdNewObject.value;
            }
          });
          newArray.push(newObject);
        });
        return newArray;
      } else {
        let prevObjectArray = Object.keys(prevObject).filter(
          (val) => !prevArray.includes(val)
        ); //get keys of object

        let newObject = {};

        prevArray.forEach((newGiven) => {
          let holdNewObject = this.convertValue(newGiven, prevObject[newGiven]);
          if (holdNewObject.isConvertable) {
            //
            if (holdNewObject.keyChange) {
              newObject[holdNewObject.keyChange] = holdNewObject.value;
            } else {
              newObject[newGiven] = holdNewObject.value;
            }
          } else {
            newObject[newGiven] = holdNewObject.value;
          }
        });
        prevObjectArray.forEach((newGiven) => {
          let holdNewObject = this.convertValue(newGiven, prevObject[newGiven]);

          if (holdNewObject.isConvertable) {
            //
            if (holdNewObject.keyChange) {
              newObject[holdNewObject.keyChange] = holdNewObject.value;
            } else {
              newObject[newGiven] = holdNewObject.value;
            }
          } else {
            newObject[newGiven] = holdNewObject.value;
          }
        });
        return newObject;
      }
    },
    removeKeyArray(givenItem, removeArray) {
      if (Array.isArray(givenItem)) {
        let subItem = [...givenItem];
        let newItem = [];
        subItem.forEach((sub) => {
          newItem.push(this.removeKeys(sub, removeArray));
        });
        return newItem;
      } else {
        let subItem = this.removeKeys(givenItem, removeArray);
        return subItem;
      }
    },
  },
  computed: {
    filteredApi() {
      let filter = { ...this.api };
      filter = this.removeKeys(filter, this.toDelete);
      filter = this.removeEmptyElements(filter);

      let trueObject = {};
      Object.keys(this.trueFilter).forEach((trueF) => {
        // console.log(trueF)
        let newObject = {};
        this.trueFilter[trueF].forEach((item) => {
          // console.log(item)
          let itemType = typeof item;
          if (itemType == "string") {
            let value = this.findPropFromObj(filter, item);
            // console.log(item,value)
            if (value != null) {
              let holdObject = this.convertValue(item, value, filter);

              if (holdObject.isConvertable) {
                if (holdObject.keyChange) {
                  newObject[holdObject.keyChange] = holdObject.value;
                } else {
                  newObject[item] = holdObject.value;
                }
              } else {
                newObject[item] = holdObject.value;
              }
            }
          } else {
            if (Array.isArray(item)) {
              let value = this.findPropFromObj(filter, item[0]);
              if (value != null) {
                if (item[1]) {
                  newObject[item[0]] = this.sortManual(value, item[1]);
                }
                if (item[2]) {
                  newObject[item[0]] = this.removeKeys(
                    newObject[item[0]],
                    item[2]
                  );
                }
              }
            } else {
              let contentObject = {};
              if (item.content) {
                item.content.forEach((cont) => {
                  if (Array.isArray(cont)) {
                    let value = this.findPropFromObj(filter, cont[0]);
                    if (value != null) {
                      if (cont[1]) {
                        contentObject[cont[0]] = this.sortManual(
                          value,
                          cont[1]
                        );
                      }
                      if (cont[2]) {
                        contentObject[cont[0]] = this.removeKeys(
                          contentObject[cont[0]],
                          cont[2]
                        );
                      }
                    }
                  } else {
                    // console.log(cont,'test')
                    // contentObject[cont] = this.findPropFromObj(filter, cont); //change
                    let currentValue = this.findPropFromObj(filter, cont);
                    let convertedValue = this.convertValue(
                      cont,
                      currentValue,
                      filter
                    );
                    if (convertedValue.isConvertable) {
                      if (convertedValue.keyChange) {
                        contentObject[convertedValue.keyChange] =
                          convertedValue.value;
                      } else {
                        contentObject[cont] = convertedValue.value;
                      }
                    } else {
                      contentObject[cont] = convertedValue.value;
                    }
                  }
                });
              }
              newObject[item.mainKey] = contentObject;
            }
          }
        });

        trueObject[trueF] = newObject;
      });
      trueObject = this.removeEmptyElements(trueObject);
      trueObject = this.clearEmpties(trueObject);
      // console.log(trueObject)
      return trueObject;
    },
  },
};
</script>

<style scoped>
.toBold {
  font-weight: 200;
}
nav {
  font-size: inherit;
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
.tab-pane {
  /*padding: 1rem;*/
  font-weight: 300;
}
.spanDisclaimer {
  background: rgba(211, 211, 211, 0.11);
  text-rendering: optimizeLegibility;
  margin-top: 40px;
  padding: 20px;
}
.disclaimer {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
}
.blur {
  color: transparent;
  /*z-index: 888;*/
  text-shadow: 0 0 10px #000;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
