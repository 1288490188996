<script>
import BlockPageHeader from "@/components/BlockPageHeader";

export default {
  data() {
    return {
      date: null,
      checkoutdate: null,
    };
  },
  components: {
    BlockPageHeader,
  },
  // mounted() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
  // updated() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
};
</script>
<template>
  <section class="bg-profile d-table w-100" id="start">
    <BlockPageHeader
      :title="$t('terms.header.title')"
      :description="$t('terms.header.description')"
      imageurl="images/site-content/terms/header.jpg"
      imageurl2="images/page-about-us/about-us-2.jpeg"
      half="true"
    ></BlockPageHeader>
    <div class="container mt-60 mt-60 terms">
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <p
            class="text-lighter"
            v-html="$t('terms.statement', { br: '<br/>' })"
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>
