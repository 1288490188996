<script>
import ContactForm from "@/components/ContactForm.vue";
import ContactDetails from "@/components/SnippetContactDetails.vue";

export default {
  name: "ContactUs",
  components: {
    ContactDetails,
    ContactForm,
  },
  data() {
    return {
      //
    };
  },
  // mounted() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
  // updated() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
};
</script>
<template>
  <section class="bg-profile d-table w-100" id="start">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card map border-0">
            <div class="card-body">
              <iframe
                width="100%"
                height="600"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=nl&amp;q=J.P.%20Broekhovenstraat%2037b,%20Elburg+YachtFull%20International%20Yachtbrokers&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div
          class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
        >
          <div class="card rounded shadow">
            <div class="card-body py-2">
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-lg-7 col-md-6 order-1 order-md-2">
          <div class="title-heading ml-lg-4 p-5">
            <h4 class="mb-4" v-html="$t('contact.page-title')"></h4>
            <p
              class="text-lighter"
              v-html="$t('contact.page-introduction')"
            ></p>
            <ContactDetails place="default"></ContactDetails>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </section>
</template>
