<template>
  <span v-if="conditions"
    >({{ numberWithCommas(yachtgenericInfo.draftUp) }}/{{
      numberWithCommas(yachtgenericInfo.draftDown)
    }})</span
  >
  <span v-else>{{ numberWithCommas(yachtgenericInfo.draft) }}</span>
</template>
<script>
export default {
  props: {
    yachtgenericInfo: {
      type: Object,
    },
  },
  methods: {
    numberWithCommas(x) {
      var dividefirst = x / 100;
      var roundedString = dividefirst.toFixed(2); // 2.00
      // return x.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ".");
      //  console.log(roundedString);
      return roundedString;
    },
  },
  computed: {
    conditions() {
      this.yachtgenericInfo;
      return (
        this.yachtgenericInfo.variableDraft === true &&
        this.yachtgenericInfo.variableDraft !== null
      );
    },
  },
};
</script>
