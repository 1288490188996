import { createRouter, createWebHistory } from "vue-router";
import ViewLanding from "@/views/ViewLanding";
import ViewAboutUs from "@/views/ViewAboutUs";
import ViewNewYachts from "@/views/ViewNewYachts";
import ViewYachts from "@/views/ViewYachts";
import ViewBrokerage from "@/views/ViewBrokerage";
import ViewServices from "@/views/ViewServices";
import ViewPartners from "@/views/ViewPartners";
import ViewContact from "@/views/ViewContact";
import ViewCompany from "@/views/ViewCompany";
import ViewPrivacyStatement from "@/views/ViewPrivacyStatement";
import ViewCookieStatement from "@/views/ViewCookieStatement";
import ViewTerms from "@/views/ViewTerms";

const routes = [
  {
    path: "/",
    name: "ViewLanding",
    component: ViewLanding,
  },
  {
    path: "/about-us",
    name: "ViewAboutUs",
    component: ViewAboutUs,
  },
  {
    path: "/newyachts",
    name: "ViewNewYachts",
    component: ViewNewYachts,
  },
  {
    path: "/yachts",
    name: "yachts",
    component: ViewYachts,
  },
  {
    // will always redirect /users/123/posts to /users/123/profile
    path: "/brokerage-detail/:id",
    redirect: (to) => {
      // the function receives the target route as the argument
      // a relative location doesn't start with `/`
      // or { path: 'profile'}
      return "/brokerage/" + to.params.id;
    },
  },
  {
    path: "/brokerage/:id",
    component: ViewBrokerage,
  },

  {
    path: "/services",
    name: "services",
    component: ViewServices,
  },
  {
    path: "/partners",
    name: "partners",
    component: ViewPartners,
  },
  {
    path: "/contact",
    name: "contact",
    component: ViewContact,
  },
  {
    path: "/company",
    name: "company",
    component: ViewCompany,
  },
  {
    path: "/privacy-statement",
    name: "privacy-statement",
    component: ViewPrivacyStatement,
  },
  {
    path: "/cookie-statement",
    name: "cookie-statement",
    component: ViewCookieStatement,
  },
  {
    path: "/terms",
    name: "terms",
    component: ViewTerms,
  },
];

const router = createRouter({
  history: createWebHistory(),
  // mode: "history",
  routes,
});
// router.onError((error) => {
//   console.error(error);
//   if (/ChunkLoadError:.*failed./i.test(error.message)) {
//     window.location.reload();
//   } else if (/Loading.*chunk.*failed./i.test(error.message)) {
//     window.location.reload();
//   }
// });

export default router;
