<template>
  <div class="video-wrapper">
    <video-background
      class="video-wrapper"
      muted
      loop
      :src="selectedVideo + '1080.mp4'"
      :poster="urlPoster"
      :sources="[
        { src: selectedVideo + '1080.mp4', res: 1080, autoplay: true },
        { src: selectedVideo + '240.mp4', res: 240, autoplay: true },
        { src: selectedVideo + '360.mp4', res: 360, autoplay: true },
        { src: selectedVideo + '720.mp4', res: 720, autoplay: true },
      ]"
    >
    </video-background>
  </div>
</template>
<style scoped>
.video-wrapper {
  position: relative;
  top: 0;
  left: 0;
  min-height: 50vh;
  max-height: 60vh;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  overflow: hidden;
}
</style>
<script>
export default {
  data() {
    return {
      loading: false,
      videobgUrls: [
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-1-", // Jongert
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-2-", // Noordkaper
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-3-", // Satellite 44
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-4-", // Old men
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-5-", // Sweden
        "https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-6-", // RSC VIDEO HERE
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-7-", // Borkumriff
      ],
      selectedVideo: null,
    };
  },
  props: {
    urlVideo: {
      type: String,
      default:
        "https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-6-",
    },
    urlPoster: {
      type: String,
      default: "@/assets/images/video_placeholder.jpg",
    },
    videoUrlHeader: {
      type: String,
      default: "",
    },
  },
  methods: {
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
  },
  created() {
    if (this.videoUrlHeader) {
      this.selectedVideo = this.videoUrlHeader;
    } else {
      this.selectedVideo = this.randomItem(this.videobgUrls);
    }
  },
};
</script>
