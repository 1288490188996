<template>
  <section class="bg-profile d-table w-100" id="start">
    <BlockPageHeader
      :title="$t('company.page-title')"
      :description="$t('company.page-description')"
      imageurl="images/site-content/about-us/header.jpg"
      half="true"
    ></BlockPageHeader>
    <section class="section pb-3 m-4">
      <div class="container mt-60">
        <div class="row align-items-center">
          <div class="title-heading ml-lg-0">
            <h4 class="mb-4">{{ $t("company.section-title") }}</h4>
            <p class="text-lighter">{{ $t("company.section-introduction") }}</p>

            <div class="media contact-detail align-items-center mt-3">
              <div class="media-body content">
                <h4 class="title font-weight-bold mb-0">
                  {{ $t("company.label_foundation_IBAN") }}
                </h4>
                <a class="text-primary">{{
                  $t("company.value_foundation_IBAN")
                }}</a>
              </div>
            </div>

            <div class="media contact-detail align-items-center mt-3">
              <div class="media-body content">
                <h4 class="title font-weight-bold mb-0">
                  {{ $t("company.label_CoC") }}
                </h4>
                <a class="text-primary">{{ $t("company.value_CoC") }}</a>
              </div>
            </div>
            <div class="media contact-detail align-items-center mt-3">
              <div class="media-body content">
                <h4 class="title font-weight-bold mb-0">
                  {{ $t("company.label_BIC") }}
                </h4>
                <a class="text-primary">{{ $t("company.value_BIC") }}</a>
              </div>
            </div>
            <div class="media contact-detail align-items-center mt-3">
              <div class="media-body content">
                <h4 class="title font-weight-bold mb-0">
                  {{ $t("company.label_VAT") }}
                </h4>
                <a class="text-primary">{{ $t("company.value_VAT") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {{ $t("company.location1.description") }}
    <BlockHalfContentHalfMedia
      :langLocationID="1"
      place="elburg"
      :title="$t('company.location1.title')"
      :description="
        $t('company.location1.description', {
          br: '<br />',
          strong: '<strong>',
          estrong: '</strong>',
        })
      "
      :phone="$t('company.location1.phone')"
      :whatsapp="$t('company.location1.whatsapp')"
      imageurl="images/site-content/about-us/history.jpg"
    ></BlockHalfContentHalfMedia>
    <BlockHalfContentHalfMedia
      :langLocationID="2"
      imageLeft
      place="livorno"
      :title="$t('company.location2.title')"
      :description="$t('company.location2.description')"
      :phone="$t('company.location2.phone')"
      :whatsapp="$t('company.location2.whatsapp')"
      :carouselUrl="false"
      imageurl="images/site-content/company/livorno1.jpg"
    ></BlockHalfContentHalfMedia>
  </section>
</template>

<script>
import BlockPageHeader from "@/components/BlockPageHeader";
import BlockHalfContentHalfMedia from "@/components/BlockHalfContentHalfMedia";
export default {
  name: "CompanyDetails",
  components: {
    BlockPageHeader,
    BlockHalfContentHalfMedia,
  },
};
</script>
