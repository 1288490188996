<script>
import BlockPageHeader from "@/components/BlockPageHeader";
export default {
  name: "cookie-statement.vue",
  components: {
    BlockPageHeader,
  },
  // mounted() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
  // updated() {
  //   const el = document.querySelector(this.$route.hash);
  //   el && el.scrollIntoView();
  // },
};
</script>
<template>
  <section class="bg-profile d-table w-100" id="start">
    <BlockPageHeader
      :title="$t('cookie-statement.header.title')"
      :description="$t('cookie-statement.header.description')"
      imageurl="images/site-content/about-us/header.jpg"
      half="true"
    ></BlockPageHeader>

    <div class="container mt-60 mt-60">
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <p
            class="text-lighter"
            v-html="
              $t('cookie-statement.statement', {
                account: 'info',
                domain: 'yachtfull.com',
                br: '<br/>',
              })
            "
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>
