<script>
import ComponentTitle from "@/components/ComponentTitle";
import ComponentContactForm from "@/components/ContactForm";

export default {
  name: "BlockContact",
  data: function () {
    return {
      //
    };
  },
  components: {
    ComponentContactForm,
    ComponentTitle,
  },

  props: ["title", "description", "background"],
};
</script>

<template>
  <section class="section" :class="background === 'grey' ? 'bg-light' : ''">
    <div class="container">
      <div class="row">
        <ComponentTitle :title="title" :description="description">
        </ComponentTitle>

        <div class="col-lg-7 col-md-6 col-sm-12 mt-0 pt-0">
          <div class="card rounded shadow">
            <div class="card-body py-2">
              <ComponentContactForm yachtid="0"></ComponentContactForm>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12 mt-0 mt-4">
          <div class="card border-0 shadow rounded">
            <div class="map map-height-two">
              <iframe
                class="iframemap"
                src="https://maps.google.com/maps?width=100vw25&amp;height=600&amp;hl=nl&amp;q=J.P.%20Broekhovenstraat%2037b,%20Elburg+YachtFull%20International%20Yachtbrokers&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.iframemap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
}
</style>
