<template>
  <h4 v-if="this.item.Generic">
    <span v-if="this.item.Generic.location !== null">Location of </span
    >{{ item.Brand.brand.name }}
    {{ item.Brand.brand.model }}
    <span v-if="item.Generic.location === null">
      waiting for dock location</span
    >
  </h4>
  <div v-if="this.item.Generic && item.Generic.location !== null">
    <iframe
      width="100%"
      height="450"
      :src="
        'https://maps.google.com/maps?width=100%25&amp;height=50%&amp;hl=nl&amp;q=' +
        item.Generic.location +
        '+(' +
        item.Generic.location +
        ')&amp;t=&amp;z=7&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
      "
    ></iframe>
  </div>

  <div v-else>
    <iframe
      width="100%"
      height="450"
      src="https://maps.google.com/maps?width=100%25&amp;height=50%&amp;hl=nl&amp;q=Europe+('Europe')&amp;t=&amp;z=4&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    ></iframe>
  </div>
</template>
<script>
export default {
  props: {
    item: {},
  },
};
</script>
