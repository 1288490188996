<template>
  <header
    id="topnav"
    class="defaultscroll"
    :class="{ 'bg-white': isWhiteNavbar === false }"
  >
    <div class="container">
      <!-- Logo container-->
      <router-link to="/" class="logo">
        <img
          src="@/assets/images/yachtfull-logo-white-bg-no-brands.svg"
          height="50"
          class="logo-light-mode"
          alt=""
        />
        <img
          src="@/assets/images/yachtfull-logo-blue-bg-no-brands.svg"
          height="50"
          class="logo-dark-mode"
          alt=""
        />
      </router-link>
      <!--end navigation-->
    </div>
    <!--end container-->
    <!--  <header-->
    <!--    id="topnav"-->
    <!--    class="defaultscroll sticky"-->
    <!--    :class="{ 'bg-white': isWhiteNavbar === true }"-->
    <!--  >-->
    <div class="container">
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a
            class="navbar-toggle"
            @click="toggleMenu()"
            :class="{ open: isCondensed === true }"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>

      <div id="navigation">
        <!-- Navigation Menu-->
        <ul class="navigation-menu" :class="{ 'nav-light': navLight === true }">
          <li class="has-submenu">
            <a href="javascript:void(0)" @click="onMenuClick">{{
              $t("navigation.menu.about-us")
            }}</a
            ><span class="menu-arrow"></span>
            <ul class="submenu">
              <li>
                <router-link to="/about-us" class="side-nav-link-ref">{{
                  $t("navigation.menu.about-us-location")
                }}</router-link>
              </li>
              <li>
                <router-link to="/about-us" class="side-nav-link-ref">{{
                  $t("navigation.menu.about-us-impression")
                }}</router-link>
              </li>
              <li>
                <router-link to="/contact" class="side-nav-link-ref">{{
                  $t("navigation.menu.contact")
                }}</router-link>
              </li>
              <li>
                <router-link to="/company" class="side-nav-link-ref">{{
                  $t("navigation.menu.contact_company")
                }}</router-link>
              </li>
              <li>
                <router-link to="/partners" class="side-nav-link-ref">{{
                  $t("navigation.menu.about-us-partners")
                }}</router-link>
              </li>
            </ul>
          </li>
          <li class="has-submenu">
            <router-link
              to="/yachts"
              class="side-nav-link-ref"
              @click="onMenuClick"
            >
              {{ $t("navigation.menu.yachts-brokerage") }}</router-link
            ><span class="menu-arrow"></span>
            <ul class="submenu">
              <li>
                <router-link to="/yachts" class="side-nav-link-ref">{{
                  $t("navigation.menu.sailing-yachts")
                }}</router-link>
              </li>
              <li>
                <a
                  href="https://www.elburgyachting.nl"
                  target="_blank"
                  class="side-nav-link-ref"
                  >{{ $t("navigation.menu.motor-yachts") }}</a
                >
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/newyachts" class="side-nav-link-ref">{{
              $t("navigation.menu.newyachts")
            }}</router-link>
          </li>
          <li class="has-submenu">
            <a href="javascript:void(0)" @click="onMenuClick">{{
              $t("navigation.menu.services")
            }}</a
            ><span class="menu-arrow"></span>
            <ul class="submenu">
              <li>
                <router-link to="/services" class="side-nav-link-ref">{{
                  $t("navigation.menu.services-selling")
                }}</router-link>
              </li>
              <li>
                <router-link to="/services" class="side-nav-link-ref">{{
                  $t("navigation.menu.services-purchase-support")
                }}</router-link>
              </li>
              <li>
                <router-link to="/services" class="side-nav-link-ref">{{
                  $t("navigation.menu.services-valuation")
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <!--end navigation menu-->
        <!--end login button-->
      </div>
      <!--end navigation-->
    </div>
    <!--end container-->
  </header>
</template>
<style>
.submenuToggle {
  display: block !important;
}
@media screen and (max-width: 414px) {
  .logo-light-mode {
    height: 40px;
  }
}
</style>
<script>
/**
 * Navbar component
 */

export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },

  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>
