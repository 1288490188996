<script>
import ComponentTitle from "@/components/ComponentTitle";
import Carousel from "@/components/Carousel.vue";

export default {
  name: "BlockDealership",
  data: function () {
    return {
      vfImages: [
        // {
        //   id: "1",
        //   big: "images/site-content/dealers/dealer1.jpg",
        //   thumb: "images/site-content/dealers/dealer1_thumb.jpg",
        // },
        {
          id: "1",
          big: "images/site-content/new-yachts/Bestevaer_53ST_Seasqaure_Aluminium_Sailing_Yacht_Exterior_18.jpg",
          thumb:
            "images/site-content/new-yachts/Bestevaer_53ST_Seasqaure_Aluminium_Sailing_Yacht_Exterior_18.jpg",
        },
        {
          id: "2",
          big: "images/site-content/new-yachts/Bestevaer_53ST_Seasqaure_Aluminium_Sailing_Yacht_Exterior_3.jpg",
          thumb:
            "images/site-content/new-yachts/Bestevaer_53ST_Seasqaure_Aluminium_Sailing_Yacht_Exterior_3.jpg",
        },
        {
          id: "3",
          big: "images/site-content/new-yachts/Bestevaer_53ST_Aegle_Aluminium_Sailing_yacht_Interior_45.jpg",
          thumb:
            "images/site-content/new-yachts/Bestevaer_53ST_Aegle_Aluminium_Sailing_yacht_Interior_45.jpg",
        },
        {
          id: "4",
          big: "images/site-content/new-yachts/Bestevaer_53ST_Aegle_Aluminium_Sailing_yacht_Interior_42.jpg",
          thumb:
            "images/site-content/new-yachts/Bestevaer_53ST_Aegle_Aluminium_Sailing_yacht_Interior_42.jpg",
        },
        // {
        //   id: "2",
        //   big: "images/site-content/dealers/dealer2.jpg",
        //   thumb: "images/site-content/dealers/dealer2_thumb.jpg",
        // },
        // {
        //   id: "3",
        //   big: "images/site-content/dealers/dealer3.jpg",
        //   thumb: "images/site-content/dealers/dealer3_thumb.jpg",
        // },

        // {
        //   id: '4',
        //   big: 'images/site-content/dealers/dealer4.jpg',
        //   thumb: 'images/site-content/dealers/dealer4_thumb.jpg'
        // },
        // {
        //   id: '5',
        //   big: 'site-content/dealers/dealer5.jpg',
        //   thumb: 'site-content/dealers/dealer5_thumb.jpg'
        // }
      ],
    };
  },
  components: {
    ComponentTitle,
    Carousel,
  },
  methods: {
    //
  },
  props: ["title", "description", "background"],
};
</script>
<template>
  <section class="section" :class="background === 'grey' ? 'bg-light' : ''">
    <div class="container">
      <div class="row">
        <ComponentTitle :title="title" :description="description">
        </ComponentTitle>

        <carousel
          :starting-image="0"
          :images="vfImages"
          :auto-slide-interval="4500"
          :show-progress-bar="true"
        ></carousel>

        <div class="col-lg-12 text-center col-md-4 mt-2 pt-2">
          <router-link
            to="/newyachts"
            class="text-muted readmore btn btn-primary"
            >{{ $t("navigation.buttons.see-more") }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>
